@import 'variables.scss';
@import 'font.scss';
@import './theme.scss';
@import 'material-icons/iconfont/material-icons.css';
@import 'material-symbols';


// Custom Theming for Angular Material
// @import './theme.scss';

/* Page and headers */
html, body {
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0;
  font-family: $font;
  font-weight: $font-weight-light;
  background-color: $background-color;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  touch-action: manipulation;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.uppercase {
  text-transform: uppercase;
}

.mat-mdc-form-field{
  .no-margin-bottom {
    margin-bottom: -$padding-small !important;
    .mat-mdc-form-field-bottom-align::before {
      height: 0 !important;
    }
  }
}

.mat-mdc-mini-fab{
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
  border: 0pt solid transparent;
  border-radius: 3pt;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-color-hover;
}

::-webkit-scrollbar-track {
  background: $scrollbar-track;
  border: 3pt solid transparent;
  background-clip: content-box;
}


.map-info-content   {
  align-items: left;
  background-color: $primary-color;
  border-radius: 12px;
  color: $color-contrast;
  display: flex;
  font-size: 8px;
  height: 12px;
  width: 12px;
  justify-content: center;
  align-items: center;
  padding: 2px;
  position: relative;
  position: relative;
  transition: all 0.15s ease-out;
  z-index: 2;
}

.map-info-content::after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid $primary-color;
  content: "";
  color: $color-contrast;
  height: 0;
  left: 50%;
  position: absolute;
  top: 84%;
  transform: translate(-50%, 0);
  transition: all 0.15s ease-out;
  width: 0;
  z-index: 1;
}

.map-info-content .icon {
  font-family: $font;
  text-align: center;
  color: $color-contrast;
}

.map-info-content  {
  .details {
    display: none;
    flex-direction: column;
    flex: 1;
    margin: $padding-x-small;
    justify-content: space-between;
    align-items: flex-start;
    color: $color-contrast;
    font-size: 14px;
    line-height: 16px;
    a {
      color: $color-contrast !important;
      text-decoration: underline !important;
      &:visited {
        color: $color-contrast !important;
      }
    }
    .space-row {
      display: flex;
      justify-content: space-between;

    }
  }
  .sep {
    display: inline-block;
    width: 20px;
  }
}

/*
 * map-info-content   styles in highlighted state.
 */
.map-info-content.highlight {
  border-radius: 8px;
  height: 80px;
  padding: 8px;
  width: auto;
}

.map-info-content.highlight::after {
  border-top: 22px solid $primary-color;
}

.map-info-content.highlight {
  .icon {
    display: none;
  }
  .details {
    display: flex;
  }
}
