$padding-xxx-small: 2px;
$padding-xx-small: 4px;
$padding-x-small: 8px;
$padding-small: 16px;
$padding: 24px;
$padding-large: 36px;
$padding-x-large: 48px;
$padding-xx-large: 64px;
$padding-xxx-large: 96px;

$color: #212121;
$color-opaque: #888;
$color-contrast: #fff;
$background-color: #fff;
$accent-color: #ff887f;
$primary-color: #273690;
$secondary-color: var(--color-secondary);
$tertiary-color: var(--color-tertiary);

$card-bg: #fafafa;

$framework-border: 1px solid #f0f0f0;
$border-radius: 4px;

$font: 'Inter', 'Roboto', sans-serif;
$font-accent: 'Roboto', sans-serif;

$font-weight-semi-bold: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

$animation-duration-fast: .1s;
$animation-duration: .25s;
$animation-duration-slow: 0.5s;

$breakpoint-xxs: 0px;
$breakpoint-xs: 380px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1920px;

$navbar-height: 60px;

$content-max-width: 1600px;

$scrollbar-color: var(--color-scrollbar);
$scrollbar-track: var(--color-scrollbar-track);
$scrollbar-color-hover: var(--color-scrollbar-hover);
