
@use '@angular/material' as mat;
@use 'themem3-theme.scss';

@include mat.elevation-classes();
@include mat.app-background();
@include mat.color-variants-backwards-compatibility(themem3-theme.$light-theme);


 html {
   // Apply the light theme by default
   @include mat.elevation-classes();
   @include mat.app-background();
   @include mat.all-component-themes(themem3-theme.$light-theme);
   @include mat.system-level-colors(themem3-theme.$light-theme);
   @include mat.system-level-typography(themem3-theme.$light-theme);
}

:root {
     --color-primary: #{mat.get-theme-color(themem3-theme.$light-theme, primary,  50)};
     --color-secondary: #{mat.get-theme-color(themem3-theme.$light-theme, secondary,  50)};
     --color-tertiary: #{mat.get-theme-color(themem3-theme.$light-theme, tertiary,  50)};
     --color-secondary-xxx-light: #{mat.get-theme-color(themem3-theme.$light-theme, secondary,  95)};
     --color-scrollbar: #{mat.get-theme-color(themem3-theme.$light-theme, primary,  50)};
     --color-scrollbar-hover: #{mat.get-theme-color(themem3-theme.$light-theme, primary,  60)};
     --color-scrollbar-track: #{mat.get-theme-color(themem3-theme.$light-theme, primary,  90)};
}

